import React from "react"
import { graphql } from "gatsby"
import { Layout, SEO } from "../components"
import PostBox from "../components/PostBox"

type Props = {
  data: {
    orgContent: {
      fields: {
        slug: string
      }
      html: JSX.Element
      metadata: Record<string, string>
      wordCount: number
      timeToRead: number
    }
  }
}

const Post = (props: Props) => {
  const post = props.data.orgContent

  const { title, date, tags, image, description } = post.metadata
  const attrs = {
    title,
    date,
    content: post.html,
    tags,
    slug: post.fields.slug,
    image,
    description,
    snippet: post.snippet,
    wordCount: post.wordCount,
    timeToRead: post.timeToRead,
  }

  return (
    <Layout nosidebar>
      {post ? (
        <>
          <SEO {...attrs} />
          <PostBox key={post.fields.slug} {...attrs} />
        </>
      ) : null}
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostQuery($id: String!) {
    orgContent(id: { eq: $id }) {
      fields {
        slug
      }
      html
      metadata {
        title
        date(formatString: "ll")
        tags
        image
        description
      }
      snippet
      wordCount
      timeToRead
    }
  }
`

export default Post
